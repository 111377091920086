import { Box, Text,} from '@chakra-ui/layout'
import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import { useUsers } from '../../../hooks';

const Transactions = () => {

    // 
    const shopInfo = useUsers().sales_data.sale;

    const tableData = shopInfo.payments.map((payment, index) => {

        let getAmountPaid = 0;
        if (index === 0) {
            getAmountPaid = shopInfo.total;
        } else {
            for(let i = 0; i <shopInfo.payments.length; i++) {
                getAmountPaid += shopInfo.payments[i].amount;
            }
        }
    
        return {
            deptBefore: index === 0 ? shopInfo.total : shopInfo.total - (getAmountPaid - payment.amount),
            deptAfter: index === 0 ? shopInfo.total - payment.amount : shopInfo.total - getAmountPaid,
            amountPaid: payment.amount,
            dateOfPayment: payment.registered,
        };
    });

    // number formater
    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true,
        minimumFractionDigits: 0
    });

    // date formater
    const saleDate = (date) => {
        const inputDate = new Date(date);

        const options = { year: "numeric", month: "short", day: "numeric", };
        const formatter = new Intl.DateTimeFormat("en-US", options);

        const outputDateStr = formatter.format(inputDate);

        return outputDateStr;
    }

    return (
        <Box>
            {/* header */}
            <Text
                color='#9A9A9A'
                fontSize='13px'
                fontWeight='500'
                pl='18px'
            >
                HISTORIA YA MALIPO
            </Text>

            {/* line */}
            <Box h='1px' w='100%' bg='#F3F3F3'></Box>

            {/* transactions */}
            <TableContainer mt='12px' maxWidth='100%'>
                <Table size='sm' w='100%'>
                    <Thead >
                        <Tr>
                            <Th fontSize='10px'>Tarehe</Th>
                            <Th fontSize='10px' >Deni kabla</Th>
                            <Th fontSize='10px'>Malipo</Th>
                            <Th fontSize='10px'>Deni baada</Th>
                        </Tr>
                    </Thead>
                    <Tbody mt='20px'>
                        {
                            tableData.map((transaction, index) => (
                                <Tr key={index} fontSize='10px' fontWeight='400' textAlign='center'>
                                    <Td fontSize='10px'>{saleDate(transaction.dateOfPayment)}</Td>
                                    <Td fontSize='10px'>{formatter.format(transaction.deptBefore)}</Td>
                                    <Td fontSize='10px' color='#00BA07' >{formatter.format(transaction.amountPaid)}</Td>
                                    <Td fontSize='10px' color='#FD003A' >{formatter.format(transaction.deptAfter)}</Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Transactions