import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import { useUsers } from '../../../hooks';

const DeptDetails = () => {

    // 
    const shopInfo = useUsers().sales_data.sale;

    // number formater
    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true,
        minimumFractionDigits: 0
    });
    return (
        <Box
            // w='100%'
            mr='18px'
            ml='18px'
            mb='45px'
        >
            <Text
                textAlign='center'
                fontSize='14px'
                fontWeight='400'
                marginBlockStart='0px'
                marginBlockEnd='0px'
                mb='15px'
            >
                {shopInfo.customer.customer_name}, Kiasi Unachodaiwa
            </Text>

            {/* amount */}
            <Text
                textAlign='center'
                fontSize='30px'
                color='#FD003A'
                marginBlockStart='0px'
                marginBlockEnd='0px'
                fontWeight='500'
                mb='10px'
            >
                {formatter.format(shopInfo.balance)} <Box as='span' fontSize='14px' color='black'>Tzs</Box>
            </Text>

            {/* line */}
            <Box h='1px' w='90%' bg='#F3F3F3'></Box>
        </Box>
    )
}

export default DeptDetails