import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import logo from '../../../assets/image 4.svg'

function Header() {
    return (
        <Box mb='20px'>
            <Flex justifyContent='space-between' mb='20px' pr='12px'>
                {/* logo */}
                <Image src={logo} alt='logo' />

                {/* header desc */}
                <Text
                    fontSize='12px'
                    fontWeight='500'
                    w='45%'
                >
                    Endesha biashara yako kwa kutumia kuza business App
                </Text>
            </Flex>

            {/* line */}
            <Box w='100%' h='1px' bg='#ABBEFE'></Box>
        </Box>
    )
}

export default Header