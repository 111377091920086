import { ChakraProvider } from '@chakra-ui/react'
import Index from './components';
import { AppProvider } from './hooks';

function App() {
  return (
    <ChakraProvider>
      <AppProvider>
        <Index />
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
