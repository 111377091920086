import { Box } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import Header from './parts/header'
import ShopInfo from './parts/shop_info'
import DeptDetails from './parts/dept_details'
import Transactions from './parts/transactions'
import Footer from './parts/footer'
import { SalesData } from '../network'
import { useUpdateUsers } from '../hooks'

const Index = () => {

    const pathname = window.location.pathname;

    const updateSalesData = useUpdateUsers();

    const getSalesData = useCallback(() => {
        SalesData(pathname.slice(1))
            .then(data => {
                updateSalesData({type: "SET_SALES_DATA", payload: data})
            })
                .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        getSalesData()
    }, []);

    return (
        <Box
            fontFamily='IBM Plex Sans, sans-serif'
            h='100vh'
            bg='linear-gradient(180deg, #E0EDFF 0%, #FFFFFF 51.78%)'
            pt='30px'
        >
            {/* header */}
            <Header />

            {/* Shop information */}
            <ShopInfo />

            {/* dept details */}
            <DeptDetails />

            {/* transactions */}
            <Transactions />

            {/* footer */}
            <Footer />
        </Box>
    )
}

export default Index