import { Box, Flex, Text, Link } from '@chakra-ui/react'
import React from 'react'
import { BsTelephoneFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { IoLogoWhatsapp } from "react-icons/io5";
import { useUsers } from '../../../hooks';

const ShopInfo = () => {

    // 
    const shopInfo = useUsers().sales_data.sale;
    return (
        <Box w='100%' mb='32px'>
            
            
            {/*  */}
            <Box 
                mr='15px'
                ml='15px'
                pb='10px'
                pl='15px'
                pr='15px'
                bg='white'
                borderRadius='10px'
                boxShadow='0px 4px 20px 2px rgba(0, 0, 0, 0.05)'
            >
                {/* shop header */}
                <Text
                    color='#9A9A9A'
                    fontSize='13px'
                    fontWeight='500'
                    pt='10px'
                    mb='6px'
                >
                    TAZAMA DUKA LANGU: 👇 
                </Text>

                {/* shop link */}
                <Box
                    bg='#DEFFDB'
                    borderRadius='3px'
                    fontSize='14px'
                    fontWeight='400'
                    w='fit-content'
                    mb='15px'
                >
                    <Text
                        p='5px'
                    >
                        {shopInfo.user.domain_name ? shopInfo.user.domain_name + '.kuzabusiness.com' : '-'}
                    </Text>
                </Box>

                {/* shop names */}
                <Text
                    color='#9A9A9A'
                    fontSize='13px'
                    fontWeight='500'
                >
                    {shopInfo.user.business_name ? shopInfo.user.business_name : ''}
                </Text>

                {/* contacts */}
                <Flex justifyContent='space-between' mb=''>
                    <Link href={`tel:+${shopInfo.user.phone_number}`} isExternal>
                        <SingleContant bg='#CFE1F9' icon={<BsTelephoneFill color='#073DFC' width='10px' />} textColor='#073DFC' name='Tupigie' />
                    </Link>
                    <SingleContant bg='#EEEEEE' icon={<SlLocationPin color='#000000' width='10px' />} textColor='#000000' name='Location' />

                    <Link href={`https://wa.me/${shopInfo.user.whatsapp_number ? shopInfo.user.whatsapp_number : shopInfo.user.phone_number}`} isExternal>
                        <SingleContant bg='#E5FFE2' icon={<IoLogoWhatsapp color='#00BA07' width='10px' />} textColor='#00BA07' name='Whatsapp' />
                    </Link>
                    
                </Flex>
            </Box>
        </Box>
    )
}

export default ShopInfo


// single contact section
const SingleContant = (data) => {
    return (
        <Box
            bg={data.bg}
            borderRadius='4px'
            // h='20px'
            p='4px'
        >
            <Flex gap='5px' alignItems='center' mr='8px' ml='8px'>
                {/* contact icon */}
                {data.icon}

                {/* name */}
                <Text
                    color={data.textColor}
                    fontSize='14px'
                    fontWeight='600'
                    marginBlockStart='0px'
                    marginBlockEnd='0px'
                >
                    {data.name}
                </Text>
            </Flex>
        </Box>
    )
}