import { Box, Center, Flex, Text, Link } from '@chakra-ui/layout'
import React from 'react'
import google from '../../../assets/googleplay.svg'
import appstore from '../../../assets/appstore.svg'
import { Image } from '@chakra-ui/image'
import { BsTelephoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io5";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";

const Footer = () => {
    return (
        <Box mt='10vh' pb='30px'>
            
            {/* welcome text */}
            <Center mb='10px'>
                <Text
                    textAlign='center'
                    fontSize='14px'
                    fontWeight='400'
                    w='90%'
                >
                    Download kuzabusiness,utunze na kukusanya madeni kwa urahisi na haraka, kidigitali zaidi. 
                </Text>
            </Center>

            {/* stores */}
            <Flex justifyContent='center' gap='10px' mb='30px'>
                <Link href='https://play.google.com/store/apps/details?id=com.kuza.com&hl=en&gl=US' isExternal>
                    <Image src={google} alt='google play' />
                </Link>
                <Link href='https://apps.apple.com/tz/app/kuza-business/id1616310003' isExternal>
                    <Image src={appstore} alt='app store' />
                </Link>
            </Flex>

            {/* social media */}
            <Text
                fontSize='14px'
                fontWeight='500'
                textAlign='center'
            >
                WASILIANA NA KUZABUSINESS KUPITIA 👇
            </Text>

            {/* icons of social media */}
            <Flex justifyContent='center' gap='35px' mb='30px' mt='30px'>
                <Link href='tel:+255745424248' isExternal>
                    <BsTelephoneFill size='21px' />
                </Link>
                <Link href='https://wa.me/+255745424248' isExternal>
                    <IoLogoWhatsapp size='25px' color='#00BA07' />
                </Link>
                <Link href='https://twitter.com/kuzabusiness' isExternal>
                    <AiFillTwitterCircle size='25px' color='#00acee' />
                </Link>
                <Link href='https://www.instagram.com/kuzabusiness/' isExternal>
                    <BsInstagram size='22px' />
                </Link>
            </Flex>
        </Box>
    )
}

export default Footer